import { inject, Injectable } from '@angular/core';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import {
  CasinoTagType,
  IApiCasinoRequestParams,
  ICasinoGame,
  ICasinoGameLaunchDetails,
  ICasinoGamesAndTags,
  ICasinoTag,
  IJackpot
} from 'bp-framework/dist/casino/casino.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { CasinoCategory, CasinoGamePlay, CasinoPayload } from 'bp-framework/dist/env-specific/1x2team/casino/casino.interface';
import { mapCasinoCategories } from 'bp-framework/dist/env-specific/1x2team/casino/casino.mappers';

import { CasinoApi1x2TeamService, SurfpointApi1x2TeamService } from 'bp-angular-library';

import { CasinoAbstractService } from '../../env-abstracts';

import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Casino1x2teamService extends CasinoAbstractService {
  private casinoApiService: CasinoApi1x2TeamService = inject(CasinoApi1x2TeamService);
  private authService: AuthenticationService = inject(AuthenticationService);
  // TODO: Check can we replace the SurfpointApiService with the CasinoApiService for the 1x2team provider
  private surfpointApiService: SurfpointApi1x2TeamService = inject(SurfpointApi1x2TeamService);

  public jackpots$: BehaviorSubject<IJackpot[] | null> = new BehaviorSubject<IJackpot[] | null>(null);

  public async getCasinoTags(): Promise<IApiPayload<ICasinoTag[]>> {
    // TODO: 1x2team does not have casino tags functionality. Check how to handle this
    return Promise.resolve({ data: [] });
  }

  public async getCasinoGames(params: IApiCasinoRequestParams): Promise<IApiPayload<ICasinoGamesAndTags>> {
    // TODO: 1x2team does not have casino games functionality. Check how to handle this
    return Promise.resolve({ data: { games: [], tags: [] } });
  }

  public async getCasinoGameById(id: number): Promise<ICasinoGame | null> {
    // TODO: 1x2team does not have casino games functionality. Check how to handle this
    return Promise.resolve(null);
  }

  public async getAllCasinoGroups(): Promise<IApiPayload<Partial<IListItem<number>>[]>> {
    return new Promise<IApiPayload<Partial<IListItem<number>>[]>>(async (resolve, reject) => {
      try {
        const response: CasinoPayload<CasinoCategory> | null = await this.casinoApiService.getCasinoCategories();
        const mapped: Partial<IListItem<number>>[] = response?.results && Array.isArray(response?.results) ? mapCasinoCategories(response.results) : [];
        resolve({ data: mapped });
      } catch (error) {
        return reject(new Error('Failed retrieve the list of casino categories'));
      }
    });
  }

  public async getVendorsByCategoryIdAndSubCategoryId(): Promise<IApiPayload<Partial<IListItem<number>>[]>> {
    // TODO: 1x2team does not have casino tags functionality. Check how to handle this
    return Promise.resolve({ data: [] });
  }

  public async getSubcategoriesByCategoryId(): Promise<IApiPayload<Partial<IListItem<number>>[]>> {
    // TODO: 1x2team does not have casino tags functionality. Check how to handle this
    return Promise.resolve({ data: [] });
  }

  public async getAllCasinoGames(): Promise<ICasinoGame[]> {
    return Promise.resolve([]);
  }

  public async getAllCasinoGamesForSurfpoint(): Promise<ICasinoGame[]> {
    return Promise.resolve([]);
  }

  public async getDetailsToLaunchGame(gameId: number): Promise<ICasinoGameLaunchDetails> {
    return new Promise<ICasinoGameLaunchDetails>(async (resolve, reject) => {
      try {
        const response: CasinoGamePlay | null = await this.casinoApiService.getCasinoGamePlayUrl(gameId);
        resolve({ url: response?.url, token: response?.token } as ICasinoGameLaunchDetails);
      } catch (error) {
        return reject(new Error('Failed retrieve the game launch url'));
      }
    });
  }

  public async getJackpotsListForPlayers(): Promise<any> {
    // TODO: Implement this method and check how to handle the 1x2Team fetching for the jackpot. On BetPlatform, we might have list of jackpots, while on the 1x2team, we have only one. We should have some kind of adapter.
    return Promise.resolve([]);
  }

  public async getJackpots(): Promise<IJackpot[] | null> {
    return Promise.resolve([]);
  }

  /////// JACKPOTS SERVICE

  public processJackpotAwardedEvent(playerId: string, jackpot: IJackpot): void {
    // TODO: Revisit this logic
  }

  public async getJackpotsList(): Promise<void> {
    // TODO: Revisit this logic
  }

  public updateJackpotValue(jackpot: IJackpot): void {
    // TODO: Revisit this logic
  }

  // FAVORITES
  public async getFavoriteGames(params: IApiCasinoRequestParams): Promise<IApiPayload<ICasinoGamesAndTags>> {
    return Promise.resolve({ data: { games: [], tags: [] } });
  }

  public async getIdsOfFavoriteGames(): Promise<number[]> {
    return Promise.resolve([]);
  }

  public async addGameToFavorites(gameId: number): Promise<IApiPayload<unknown>> {
    return Promise.resolve({ data: null });
  }

  public async removeGameFromFavorites(gameId: number): Promise<IApiPayload<unknown>> {
    return Promise.resolve({ data: null });
  }
}
