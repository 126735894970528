import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';

import { IonicModule, MenuController } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { INavGroup, INavItem } from 'bp-framework/dist/common/common.interface';

import { LanguageButtonComponent, PROJECT_ENV_CONFIG_TOKEN, ThemeToggleComponent } from 'bp-angular-library';

import { ContentAbstractService } from 'src/app/core/env-specific/env-abstracts';

import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';

import { filter } from 'rxjs';
import { IEnvApiBase, IEnvConfigPlayerFrontend } from 'src/app/shared/models/configuration/configuration.interface';
import { ROUTE_PATHS } from 'src/app/shared/models/routing/routing.const';
import { PACKAGE_DETAILS } from 'src/environments/package-details';

/*
        <li><a routerLink="/contact" routerLinkActive="active">Contact</a></li>
        <li><a routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a></li>
        <li><a routerLink="/terms-of-use" routerLinkActive="active">Terms of Use</a></li>
        <li><a routerLink="/responsible-gaming-policy" routerLinkActive="active">Responsible Gaming Policy</a></li>
        <li><a routerLink="/aml-kyc-policy" routerLinkActive="active">AML & KYC Policy</a></li>
        <li><a routerLink="/affiliate" routerLinkActive="active">Affiliate</a></li>
*/

const SUPPORT_NAV_GROUP: Partial<INavItem>[] = [
  {
    label: 'Contact',
    t9nKey: 'pages.support.pages.contact.title',
    path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.contact}`
  },
  {
    label: 'Privacy Policy',
    t9nKey: 'pages.support.pages.privacyPolicy.title',
    path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.privacyPolicy}`
  },
  {
    label: 'Terms of Use',
    t9nKey: 'pages.support.pages.termsOfUse.title',
    path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.termsOfUse}`
  },
  {
    label: 'Responsible Gaming Policy',
    t9nKey: 'pages.support.pages.responsibleGamingPolicy.title',
    path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.responsibleGamingPolicy}`
  },
  {
    label: 'AML & KYC Policy',
    t9nKey: 'pages.support.pages.amlKycPolicy.title',
    path: `/${ROUTE_PATHS.support.path}/${ROUTE_PATHS.support.children.amlKycPolicy}`
  },
  {
    label: 'Affiliate',
    t9nKey: 'commons.affiliate',
    path: `/${ROUTE_PATHS.myProfile.path}/${ROUTE_PATHS.myProfile.children.myAffiliate}`
  }
];

@Component({
  selector: 'app-sidemenu-primary',
  standalone: true,
  imports: [CommonModule, IonicModule, RouterLink, RouterLinkActive, ThemeToggleComponent, LanguageButtonComponent, TranslateModule],
  templateUrl: './sidemenu-primary.component.html',
  styleUrl: './sidemenu-primary.component.scss'
})
export class SidemenuPrimaryComponent implements OnInit {
  private destroyRef: DestroyRef = inject(DestroyRef);
  public authService: AuthenticationService = inject(AuthenticationService);
  private contentAbstractService: ContentAbstractService = inject(ContentAbstractService);
  private menuCtrl: MenuController = inject(MenuController);
  private router: Router = inject(Router);
  public projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase> = inject<IEnvConfigPlayerFrontend<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);

  public appVersion: any = `${this.projectConfig?.projectName} v.${PACKAGE_DETAILS.version}-${this.projectConfig?.environmentName}-${this.projectConfig?.features.frontendMode}`;

  public showThemeToggle: boolean = this.projectConfig?.environmentName === 'dev';

  public sideMenuGroups: Partial<INavGroup>[] = [];

  public secondaryPages: Partial<INavItem>[] = SUPPORT_NAV_GROUP;

  //#region lifecycle hooks
  ngOnInit(): void {
    this.subscribeToRouterEvents();
    this.sideMenuGroups = this.contentAbstractService.buildSidemenuRoutes();
  }
  //#endregion

  private subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(e => e instanceof NavigationEnd)
      )
      .subscribe((event: any): void => this.resolveCurrentSegment(event?.urlAfterRedirects));
  }

  private resolveCurrentSegment(urlAfterRedirect: string): void {
    let matchingNavItem: Partial<INavGroup> | null = null;

    this.sideMenuGroups?.forEach((item: Partial<INavGroup>) => {
      if (item.path && urlAfterRedirect.includes(item.path)) {
        matchingNavItem = item;
        matchingNavItem.expanded = Array.isArray(item?.children) && item?.children?.length > 0;
      }
    });
  }

  public closeMenu(): void {
    this.menuCtrl.close('primary-sidemenu');
  }
}
