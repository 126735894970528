import { CommonModule, Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { IonicModule, ModalController } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { EMPTY_STRING } from 'bp-framework/dist/common/common.const';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { confirmValuesMatchingValidator, DialogsService, StorageService } from 'bp-angular-library';

import { UserAbstractService } from 'src/app/core/env-specific/env-abstracts';

import { PasswordInputComponent } from '../../../password-input/password-input.component';
import { PhoneInputComponent } from '../../../phone-input/phone-input.component';

import { STORAGE_KEYS } from 'src/app/shared/models/storage/storage.const';

@Component({
  selector: 'app-sign-up-with-email',
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, TranslateModule, PhoneInputComponent, PasswordInputComponent],
  templateUrl: './sign-up-with-email.component.html',
  styleUrl: './sign-up-with-email.component.scss'
})
export class SignUpWithEmailComponent {
  private location: Location = inject(Location);
  private userAbstractService: UserAbstractService = inject(UserAbstractService);
  private dialogsService: DialogsService = inject(DialogsService);
  private modalController: ModalController = inject(ModalController);
  private formBuilder: FormBuilder = inject(FormBuilder);
  private storageService: StorageService = inject(StorageService);

  public formGroup!: FormGroup<{
    username: FormControl<string | null>;
    password: FormControl<string | null>;
    passwordConfirm: FormControl<string | null>;
    phone: FormControl<string | null>;
    bannerTag: FormControl<string | null>;
  }>;

  //#region lifecycle hooks
  ngOnInit(): void {
    this.initialize();
  }
  //#endregion

  //#region methods
  private async initialize(): Promise<void> {
    const currentUrl = this.location.path(true);
    const parsedUrl: URL = new URL(currentUrl, window.location.origin);
    const queryParams: URLSearchParams = parsedUrl.searchParams;
    console.log('searchParams _______ :', parsedUrl.searchParams);
    console.log('queryParams _______ :', queryParams);
    const bannerTag: string = queryParams.get('signUpOptionType') ?? EMPTY_STRING;
    console.log('bannerTag _______ :', bannerTag);

    const bannerTagFromStorage: string = (await this.storageService.getLocalItem(STORAGE_KEYS.bannerTag)) ?? EMPTY_STRING;

    this.formGroup = this.formBuilder.group(
      {
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
        passwordConfirm: ['', [Validators.required]],
        phone: ['', [Validators.required]],
        bannerTag: [bannerTagFromStorage] // Affiliate code
      },
      { validators: confirmValuesMatchingValidator('password', 'passwordConfirm') }
    );
  }

  public async handleSubmit(): Promise<void> {
    if (!this.formGroup.valid) {
      return;
    }

    // TODO: Create async validator that checks if the both passwords match
    const { username, password, passwordConfirm, phone } = this.formGroup.value;

    if (!username || !password || !passwordConfirm || !phone) {
      this.dialogsService.presentToast({ message: 'All fields are required', color: 'danger' });
      return;
    }

    if (password !== passwordConfirm) {
      this.dialogsService.presentToast({ message: 'Passwords do not match', color: 'danger' });
      return;
    }

    const bannerTag: string = (await this.storageService.getLocalItem(STORAGE_KEYS.bannerTag)) ?? EMPTY_STRING;

    await this.dialogsService.presentLoading();

    // TODO: We need t add a way for the user to provide an affiliate code
    this.userAbstractService
      .playerRegisterWithUsernameAndPassword(username, password, phone, bannerTag)
      .then((user: Partial<IUserDetails> | null) => {
        console.log('user registered: ', user);
        // TODO: Check with the design team if we need to show a success message to the user
        // TODO: Check with the team what should be the defaul page when the user is registered
        this.dialogsService.presentToast({ message: 'User registered successfully', color: 'success' });
        this.modalController.dismiss();
        // TODO: Check if we should reload the page after the user is registered
        window.location.reload();
      })
      .catch((error: Error) => {
        this.dialogsService.presentToast({ message: error?.message, color: 'danger' });
      })
      .finally(() => {
        this.dialogsService.dismissLoading();
      });
  }
  //#endregion
}
