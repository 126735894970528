import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { OverlayEventDetail } from '@ionic/core';

import { TranslateService } from '@ngx-translate/core';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import { ICasinoGameDetails, ICasinoSearchParams } from 'bp-framework/dist/casino/casino.interface';
import { EMPTY_STRING } from 'bp-framework/dist/common/common.const';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { IDialogResponse } from 'bp-framework/dist/dialogs/dialog.interface';
import { DIALOG_DISMISS_ROLES } from 'bp-framework/dist/dialogs/dialogs.const';

import { DialogsService } from 'bp-angular-library';

import { CasinoAbstractService } from '../core/env-specific/env-abstracts';

import { AuthenticationService } from '../core/services/auth/authentication.service';
import { PlatformService } from '../core/services/platform/platform.service';

import { CasinoSearchModalComponent } from '../shared/components/casino/casino-search-modal/casino-search-modal.component';
import { SignInSignUpComponent } from '../shared/components/forms/sign-in-sign-up/sign-in-sign-up.component';

import { IPaginationInfo } from '../shared/models/pagination/pagination.interface';
import { getPaginationInfo } from '../shared/models/pagination/pagination.utils';
import { ROUTE_PATHS } from '../shared/models/routing/routing.const';
import { SignUpOptionType, SiSuViewType } from '../shared/models/ui/ui.interface';
import { BehaviorSubject, combineLatest, debounceTime, from, pairwise, switchMap, tap } from 'rxjs';

const SEARCH_LIMIT_INCREMENT = 50;
const INITIAL_SEARCH_OFFSET = 0;

type CombinedFilterParams = [Partial<IListItem<number>> | null, Partial<IListItem<number>> | null, Partial<IListItem<number>> | null];

@Injectable({
  providedIn: 'root'
})
export class CasinoService {
  private destroyRef: DestroyRef = inject(DestroyRef);
  private casinoAbstractService: CasinoAbstractService = inject(CasinoAbstractService);
  private authService: AuthenticationService = inject(AuthenticationService);
  private platformService: PlatformService = inject(PlatformService);
  private dialogsService: DialogsService = inject(DialogsService);
  private router: Router = inject(Router);
  private translateService: TranslateService = inject(TranslateService);

  public allCasinoGames$: BehaviorSubject<Partial<ICasinoGameDetails<any, any>>[]> = new BehaviorSubject<Partial<ICasinoGameDetails<any, any>>[]>([]);
  // TODO: At the moment, we will get list of IDs for favorite games. In the future, we might need to get the whole list of favorite games with their details. For that user `getFavoriteGames()`
  public playerFavoriteGamesList$: BehaviorSubject<Partial<ICasinoGameDetails<any, any>>[]> = new BehaviorSubject<Partial<ICasinoGameDetails<any, any>>[]>([]);

  public selectedCategory$: BehaviorSubject<Partial<IListItem<number>> | null> = new BehaviorSubject<Partial<IListItem<number>> | null>(null);
  public selectedSubCategory$: BehaviorSubject<Partial<IListItem<number>> | null> = new BehaviorSubject<Partial<IListItem<number>> | null>(null);
  public selectedProvider$: BehaviorSubject<Partial<IListItem<number>> | null> = new BehaviorSubject<Partial<IListItem<number>> | null>(null);
  public searchKeyword$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public casinoNoResultsForSelectedFilters!: string;

  public showSkeletonLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public loadingMoreGamesInProgress = false;
  public showLoadMoreButton = true;

  public paginationInfo!: IPaginationInfo;

  public selectedGame$: BehaviorSubject<Partial<ICasinoGameDetails<any, any>> | null> = new BehaviorSubject<Partial<ICasinoGameDetails<any, any>> | null>(null);

  constructor() {
    this.initialize();
  }

  public async initialize(): Promise<void> {
    this.observeChangesOnFilterParams();
    this.updateTheListOfFavoriteGamesWithBackendData();
  }

  private observeChangesOnFilterParams(): void {
    combineLatest([this.selectedCategory$, this.selectedSubCategory$, this.selectedProvider$])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        pairwise(),
        debounceTime(500),
        tap(() => {
          this.showSkeletonLoader$.next(true);
        }),
        switchMap(([prev, current]: [CombinedFilterParams, CombinedFilterParams]) => {
          const [prevCategory, prevSubCategory, prevProvider] = prev;
          const [currentCategory, currentSubCategory, currentProvider] = current;

          const tmpKeyword: string = EMPTY_STRING; // IMPORTANT: For now, we are not using the keyword as part of the 'local' search. Search by keyword is performed when the user selectes global serch
          const tmpCategory: Partial<IListItem<number>> | null = currentCategory || null;
          const tmpSubCategory: Partial<IListItem<number>> | null = currentSubCategory || null;
          const tmpProvider: Partial<IListItem<number>> | null = currentProvider || null;

          this.casinoNoResultsForSelectedFilters = this.convertFilterParamsToText(tmpKeyword, tmpCategory, tmpSubCategory, tmpProvider);

          // console.log('selectedCategory', selectedCategory);
          // console.log('selectedProvider', selectedProvider);
          // console.log('selectedSubCategory', selectedSubCategory);
          // console.log('keyword', keyword);
          const tmpParams: ICasinoSearchParams = {
            offset: INITIAL_SEARCH_OFFSET,
            limit: SEARCH_LIMIT_INCREMENT, // Each time we change the filter params, we should reset the limit to the initial value
            query: tmpKeyword || undefined,
            tagIds: this.populateTagIds(tmpCategory, tmpSubCategory, tmpProvider)
          };

          return from(this.casinoAbstractService.getCasinoGames(tmpParams));
        })
      )
      .subscribe((payload: IApiPayload<ICasinoGameDetails<any, any>[]>) => {
        if (this.selectedGame$.value) {
          this.selectedGame$.next(null);
          this.navigateToCasinoGames();
        }

        this.paginationInfo = getPaginationInfo(payload);
        this.showLoadMoreButton = !this.paginationInfo?.pagination?.isLastPage;

        // IMPORTANT: IF any of the 'casino game search' params are changed, we can consider that as a new search and we can reset the selected game
        this.allCasinoGames$.next(payload?.data || []);

        this.showSkeletonLoader$.next(false);

        // TODO: On the Casino page, once the search criteria is changed, we might need to do a SCROLL TO TOP on the 'mainIonContentElRef' element
      });
  }

  public async handleLoadMoreGames(): Promise<void> {
    this.loadingMoreGamesInProgress = true;

    const tmpParams: ICasinoSearchParams = {
      offset: this.paginationInfo.nextPageOffset,
      limit: SEARCH_LIMIT_INCREMENT,
      query: EMPTY_STRING,
      tagIds: this.populateTagIds(this.selectedCategory$.value, this.selectedSubCategory$.value, this.selectedProvider$.value)
    };

    try {
      const tmpPayload: IApiPayload<ICasinoGameDetails<any, any>[]> = await this.casinoAbstractService.getCasinoGames(tmpParams);
      this.paginationInfo = getPaginationInfo(tmpPayload);
      this.showLoadMoreButton = !this.paginationInfo?.pagination?.isLastPage;

      this.allCasinoGames$.next([...this.allCasinoGames$.value, ...(tmpPayload?.data || [])]);
    } catch (error) {
      console.log('handleLoadMoreGames error', error);
    }

    setTimeout(() => {
      this.loadingMoreGamesInProgress = false;
    }, 500);
  }

  public async openSearchModal(): Promise<void> {
    const isMobileResolution: boolean = this.platformService.isMobileResolution();
    const initialBreakpoint: number = isMobileResolution ? 1 : this.calculateInitialBreakpoint();

    const dialogResult: OverlayEventDetail<IDialogResponse<any>> = await this.dialogsService.presentModal({
      component: CasinoSearchModalComponent,
      componentProps: {
        showHeader: isMobileResolution
      },
      cssClass: 'is-casino-search-modal',
      initialBreakpoint: initialBreakpoint,
      breakpoints: [0, 0.25, 0.5, initialBreakpoint],
      handleBehavior: 'cycle'
    });
  }

  private calculateInitialBreakpoint(): number {
    const fullHeight = this.platformService.height();
    const adjustedHeight = fullHeight - 80; // 80px is the height of the header on desktop
    const heightRatio = adjustedHeight / fullHeight;
    return heightRatio;
  }

  public async checkIfThePlayerCanPlay(game: Partial<ICasinoGameDetails<any, any>>): Promise<void> {
    if (!this.authService.isLoggedIn$.value) {
      return this.handleAuthToPlay(game);
    }

    this.navigateToGame(game);
  }

  private async handleAuthToPlay(game: Partial<ICasinoGameDetails<any, any>>): Promise<void> {
    // TODO: IMPORTANT: We should use URL params to control state on the page. This way we can subscribe any other component to the changes on the URL so they can change their behavior.
    const dialogResult: OverlayEventDetail<IDialogResponse<any> | null> = await this.showSignInSignUpDialog('sign-in', 'email');

    if (dialogResult?.role === DIALOG_DISMISS_ROLES.confirm && dialogResult?.data?.id) {
      this.navigateToGame(game);
    }
  }

  public async showSignInSignUpDialog(currentView: SiSuViewType, signUpOptionType: SignUpOptionType, showHeader = true): Promise<OverlayEventDetail<IDialogResponse<any>>> {
    return this.dialogsService.presentModal({
      component: SignInSignUpComponent,
      cssClass: 'sign-up-sign-in-modal',
      componentProps: {
        showHeader,
        currentView,
        signUpOptionType
      }
    });
  }

  public navigateToGame(game: Partial<ICasinoGameDetails<any, any>>): void {
    // this.router.navigate(['game', game?.id], { relativeTo: this.activatedRoute });
    // TODO: IMPORTANT: Check if this route is still needed or it can be removed/deprecated
    console.log('navigateToGame', game);
    const path = `${ROUTE_PATHS.casino}/games/${game?.id}/preview`;
    this.router.navigateByUrl(path);
  }

  public navigateToCasinoGames(): void {
    const path = `${ROUTE_PATHS.casino}/games`;
    this.router.navigateByUrl(path);
  }

  // Favorites
  public async updateTheListOfFavoriteGamesWithBackendData(): Promise<void> {
    try {
      const tmpList: Partial<ICasinoGameDetails<any, any>>[] = await this.casinoAbstractService.getFavoriteGames();
      this.playerFavoriteGamesList$.next(tmpList);
    } catch (error) {
      console.error('getListOfFavoriteGames error', error);
    }
  }

  public async toggleGameAsFavorite(game: Partial<ICasinoGameDetails<any, any>>): Promise<void> {
    // TODO: Perhaps we should somehow implement delay on the toggle button, so the user can't click it multiple times in a row
    if (!game?.id) {
      return;
    }

    const shouldBeRemovedFromFavorites: boolean = this.playerFavoriteGamesList$.value?.some((item: Partial<ICasinoGameDetails<any, any>>) => item?.id === game?.id);
    this.updateFavoriteGamesList(game, shouldBeRemovedFromFavorites);

    if (shouldBeRemovedFromFavorites) {
      await this.casinoAbstractService.removeGameFromFavorites(game?.id);
      await this.dialogsService.presentToast({ message: this.translateService.instant('commons.removedFromFavorites') });
    } else {
      await this.casinoAbstractService.addGameToFavorites(game?.id);
      await this.dialogsService.presentToast({ message: this.translateService.instant('commons.addedToFavorites') });
    }

    await this.updateTheListOfFavoriteGamesWithBackendData();
  }

  private updateFavoriteGamesList(game: Partial<ICasinoGameDetails<any, any>>, shouldBeRemovedFromFavorites: boolean): void {
    const tmpList: Partial<ICasinoGameDetails<any, any>>[] = this.playerFavoriteGamesList$.value?.filter((item: Partial<ICasinoGameDetails<any, any>>) => item?.id !== game?.id);

    if (!shouldBeRemovedFromFavorites) {
      tmpList.push(game);
    }

    this.playerFavoriteGamesList$.next(tmpList);
  }

  private populateTagIds(
    selectedCategory: Partial<IListItem<number>> | null,
    selectedSubCategory: Partial<IListItem<number>> | null,
    selectedVendor: Partial<IListItem<number>> | null
  ): string[] {
    const tmpTagIds: string[] = [];

    if (selectedCategory?.value) {
      tmpTagIds.push(`${selectedCategory.value}`);
    }

    if (selectedSubCategory?.value) {
      tmpTagIds.push(`${selectedSubCategory.value}`);
    }

    if (selectedVendor?.value) {
      tmpTagIds.push(`${selectedVendor.value}`);
    }

    return tmpTagIds;
  }

  private convertFilterParamsToText(
    keyword: string,
    selectedCategory: Partial<IListItem<number>> | null,
    selectedSubCategory: Partial<IListItem<number>> | null,
    selectedVendor: Partial<IListItem<number>> | null
  ): string {
    return this.translateService.instant('commons.casinoNoResultsForSelectedFilters', {
      keyword: keyword || '--',
      category: selectedCategory?.label || '--',
      subcategory: selectedSubCategory?.label || '--',
      provider: selectedVendor?.label || '--'
    });
  }
}
