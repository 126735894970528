import { inject, Injectable } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import {
  CasinoGamePlayMode,
  IApiCasinoRequestParams,
  ICasinoGame,
  ICasinoGameLaunchDetails,
  ICasinoGamesAndTags,
  ICasinoGamesGroup,
  ICasinoTag,
  IJackpot
} from 'bp-framework/dist/casino/casino.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { transformCasinoTagToGroupItem, transformCasinoTagToListItem } from 'bp-framework/dist/env-specific/betplatform/casino/casino.mappers';

import { BetPlatformApiAdapterService } from 'bp-angular-library';

import { CasinoAbstractService, UserAbstractService } from '../../env-abstracts';

import { AuthenticationService } from 'src/app/core/services/auth/authentication.service';

import { JackpotWinnerComponent } from '../../../../shared/components/casino/jackpot-winner/jackpot-winner.component';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasinoBetplatformService extends CasinoAbstractService {
  private bpApiAdapterService: BetPlatformApiAdapterService = inject(BetPlatformApiAdapterService);

  private translateService: TranslateService = inject(TranslateService);
  private authService: AuthenticationService = inject(AuthenticationService);

  private userAbstractService: UserAbstractService = inject(UserAbstractService);
  private modalController: ModalController = inject(ModalController);

  public jackpots$: BehaviorSubject<IJackpot[] | null> = new BehaviorSubject<IJackpot[] | null>(null);

  constructor() {
    super();
    // TODO: IMPORTANT: Move this to layout service or some other service. Or if needed create a new service for handling Jackpots
    this.getJackpotsList();
  }

  public async getAllCasinoGroups(params?: IApiCasinoRequestParams): Promise<IApiPayload<Partial<ICasinoGamesGroup<number>>[]>> {
    try {
      const response: IApiPayload<Partial<ICasinoTag>[]> = await this.bpApiAdapterService.casinoGetAllCasinoGroups(params);
      response.data = transformCasinoTagToGroupItem(response?.data);
      return response;
    } catch (error) {
      throw new Error('Failed to retrieve the list of casino games categories');
    }
  }

  public async getSubcategoriesByCategoryId(categoryId: string, params?: IApiCasinoRequestParams): Promise<IApiPayload<Partial<IListItem<number>>[]>> {
    try {
      const response: IApiPayload<Partial<ICasinoGamesGroup<number>>[]> = await this.bpApiAdapterService.casinoGetCasinoSubCategoriesByCategoryId(Number(categoryId), params);
      response.data = transformCasinoTagToListItem(response?.data);
      return response;
    } catch (error) {
      throw new Error('Failed to retreive the list of casino game subcategories');
    }
  }

  public async getVendorsByCategoryIdAndSubCategoryId(
    categoryId: string,
    subCategoryId: string,
    params?: IApiCasinoRequestParams
  ): Promise<IApiPayload<Partial<IListItem<number>>[]>> {
    try {
      const response: IApiPayload<Partial<ICasinoTag>[]> = await this.bpApiAdapterService.casinoGetVendorsByCategoryAndSubcategory(categoryId, subCategoryId, params);
      response.data = transformCasinoTagToListItem(response?.data);
      return response;
    } catch (error) {
      throw new Error('Failed to retreive the list of casino game subcategories');
    }
  }

  public async getCasinoGames(params: IApiCasinoRequestParams): Promise<IApiPayload<ICasinoGamesAndTags>> {
    try {
      const response: IApiPayload<ICasinoGamesAndTags> = await this.bpApiAdapterService.casinoGetGamesByParams(params);
      return response;
    } catch (error) {
      throw new Error(this.translateService.instant('notifications.failedToRetreiveTheListOfCasinoGames'));
    }
  }

  public async getCasinoGameById(id: number): Promise<Partial<ICasinoGame>> {
    try {
      const response: IApiPayload<Partial<ICasinoGame> | null> = await this.bpApiAdapterService.casinoGetGameById(id);

      if (!response?.data?.id) {
        throw new Error('Failed to retreive the casino game. Data is missing');
      }
      return response.data;
    } catch (error) {
      throw new Error('Failed to retreive the casino game. Call to backend failed');
    }
  }

  public async getDetailsToLaunchGame(gameId: number, mode: CasinoGamePlayMode): Promise<ICasinoGameLaunchDetails> {
    try {
      const response: IApiPayload<ICasinoGameLaunchDetails> = await this.bpApiAdapterService.casinoGetGamePlayUrl(gameId, mode);
      return response?.data;
    } catch (error) {
      throw new Error(this.translateService.instant('notifications.failedToRetreiveUrlForPlayingCasinoGame'));
    }
  }

  public async getJackpotsListForPlayers(): Promise<IApiPayload<IJackpot[]>> {
    try {
      const response: IApiPayload<IJackpot[]> = await this.bpApiAdapterService.casinoGetJackpotsGlobal({});
      return response;
    } catch (error) {
      throw new Error(this.translateService.instant('notifications.failedToRetreiveTheListOfCasinoGames'));
    }
  }

  /////// JACKPOTS SERVICE
  // TODO: IMPORTANT: Move this to layout service or some other service
  public processJackpotAwardedEvent(playerId: string, jackpot: IJackpot): void {
    if (playerId === this.authService.user$.value?.id) {
      this.presentJackpotWinnerModal(jackpot);
      this.userAbstractService.updateUserWithProfileData();
    }
  }

  public async getJackpotsList(): Promise<void> {
    const response: IApiPayload<IJackpot[]> = await this.getJackpotsListForPlayers();
    this.jackpots$.next(response?.data || []);
  }

  public updateJackpotValue(jackpot: IJackpot): void {
    const tmpJackpots: IJackpot[] = this.jackpots$.value || [];
    const index: number = tmpJackpots.findIndex(item => item.id === jackpot.id);

    if (index !== -1) {
      tmpJackpots[index] = { ...tmpJackpots[index], ...jackpot };
      this.jackpots$.next(tmpJackpots);
    }
  }

  // TODO: IMPORTANT: Move this to layout service or some other service
  private async presentJackpotWinnerModal(jackpotDetails: IJackpot): Promise<void> {
    const modal = await this.modalController.create({
      component: JackpotWinnerComponent,
      componentProps: {
        jackpotDetails
      },
      cssClass: 'full-screen-modal'
    });

    await modal.present();

    setTimeout(() => {
      modal.dismiss();
    }, 10000);
  }

  // FAVORITES
  public async getFavoriteGames(params: IApiCasinoRequestParams): Promise<IApiPayload<ICasinoGamesAndTags>> {
    try {
      const response: IApiPayload<ICasinoGamesAndTags> = await this.bpApiAdapterService.playerGetFavoriteGames(params);
      return response;
    } catch (error) {
      throw new Error(this.translateService.instant('notifications.failedToRetreiveFavoriteGames'));
    }
  }

  public async getIdsOfFavoriteGames(): Promise<number[]> {
    try {
      const response: IApiPayload<number[]> = await this.bpApiAdapterService.playerGetIdsOfFavoriteGames({});
      return response?.data || [];
    } catch (error) {
      throw new Error(this.translateService.instant('notifications.failedToRetreiveFavoriteGames'));
    }
  }

  public async addGameToFavorites(gameId: number): Promise<IApiPayload<unknown>> {
    try {
      const payload: IApiPayload<unknown> = await this.bpApiAdapterService.playerAddGameToFavotires(gameId);
      return payload;
    } catch (error) {
      throw new Error(this.translateService.instant('notifications.failedToAddGameToFavorites'));
    }
  }

  public async removeGameFromFavorites(gameId: number): Promise<IApiPayload<unknown>> {
    try {
      const payload: IApiPayload<unknown> = await this.bpApiAdapterService.removeGameFromFavorites(gameId);
      return payload;
    } catch (error) {
      throw new Error(this.translateService.instant('notifications.failedToRemoveGameFromFavorites'));
    }
  }
}
