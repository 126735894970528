import { inject } from '@angular/core';
import { Router, Routes } from '@angular/router';

import { I18nService, PROJECT_ENV_CONFIG_TOKEN, StorageService } from 'bp-angular-library';

import { ContentAbstractService, UserAbstractService } from '../env-specific/env-abstracts';

import { AuthenticationService } from '../services/auth/authentication.service';
import { IframeHandlerService } from '../services/iframe-handler/iframe-handler.service';
import { PromoService } from '../services/promo/promo.service';

import { IEnvApiBase, IEnvConfigPlayerFrontend } from 'src/app/shared/models/configuration/configuration.interface';
import { SUPPORTED_LANGUAGE_OPTIONS } from 'src/app/shared/models/i18n/i18n.const';

export function appInitFactory(
  router: Router,
  storageService: StorageService,
  authService: AuthenticationService,
  i18nService: I18nService,
  contentAbstractService: ContentAbstractService,
  userAbstractService: UserAbstractService,
  promoService: PromoService,
  iframeHandlerService: IframeHandlerService
): () => Promise<boolean> {
  const projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase> = inject<IEnvConfigPlayerFrontend<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);

  return () =>
    new Promise(async (resolve, reject) => {
      await iframeHandlerService.initialize();
      await storageService.initializeStorageService(projectConfig.projectPrefix);
      await authService.handleAppInitialization();
      await i18nService.initializeLanguage(SUPPORTED_LANGUAGE_OPTIONS);
      userAbstractService.watchForLanguageChange();

      const appRoutes: Routes = contentAbstractService.buildAllRoutes();
      router.resetConfig(appRoutes);

      await promoService.handleAppInitialization();

      resolve(true);
    });
}
